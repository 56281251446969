<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router';
import MainHeader from './components/common/MainHeader.vue';
import { useAuthStore } from './stores';
import { usePaymentsStore } from './stores/payments';
import LoginModal from './components/modals/auth/LoginModal.vue';
import CanDoModal from '@/components/modals/CanDoModal.vue';
import ToastContainer from '@/components/toast/ToastContainer.vue';
import CheckoutModal from './components/checkout/CheckoutModal.vue';
import MainFooter from '@/components/common/MainFooter.vue';
import WelcomeRewardModal from './components/modals/WelcomeRewardModal.vue';
import ResourceCheckoutModal from './components/resources/ResourceCheckoutModal.vue';
import { useResourcesStore } from './stores/resources';
import { useLegendStore } from './stores';
import WelcomeVotesModal from './components/modals/WelcomeVotesModal.vue';
import ComingSoonModal from './components/modals/ComingSoonModal.vue';
// import AuthWalletModal from './components/modals/auth/AuthWalletModal.vue'; #03
// import LoginWalletModal from './components/modals/auth/LoginWalletModal.vue'; #03

const authStore = useAuthStore();
const paymentsStore = usePaymentsStore();
const resourcesStore = useResourcesStore();
const legendStore = useLegendStore();
const router = useRouter();

// force actions again
async function handleCloseLoginModal() {
  if (authStore.redirectToHomeOnCloseLoginModal) {
    await router.push('/');
  }
}

async function handleCloseCanDoModal() {
  if (authStore.redirectToHomeOnCloseCanDoModal) {
    await router.push('/');
  }
}
</script>

<template>
  <div class="flex size-full min-h-screen flex-col bg-darker pb-0">
    <MainHeader v-if="!$route.meta.hideNavbar" />

    <RouterView class="min-h-screen" />

    <MainFooter
      v-if="!$route.meta.hideNavbar && !legendStore.isFullscreen"
      :class="`
        ${$route.path === '/direct-messages' ? 'hidden xl:flex' : 'flex'}`"
    />

    <LoginModal
      v-model="authStore.showLoginModal"
      message="To access this section you need to be logged in."
      @on:close="handleCloseLoginModal"
    />

    <CanDoModal
      v-model="authStore.canDoModalProps.showCanDoModal"
      :title="authStore.canDoModalProps.canDoTitle"
      :message="authStore.canDoModalProps.canDoMessage"
      @on:close="handleCloseCanDoModal"
    />

    <CheckoutModal
      v-if="paymentsStore.selectedProduct"
      v-model="paymentsStore.showCheckoutModal"
      :product="paymentsStore.selectedProduct"
      @on:close="paymentsStore.showCheckoutModal = false"
    />

    <ResourceCheckoutModal
      v-if="resourcesStore.checkout.product"
      v-model="resourcesStore.checkout.show"
      :product="resourcesStore.checkout.product"
      @on:close="resourcesStore.checkoutCancel"
    />

    <WelcomeRewardModal
      v-model="paymentsStore.showWelcomeRewardModal"
      :coins="paymentsStore.welcomeRewardCoins"
    />

    <WelcomeVotesModal v-model="paymentsStore.showWelcomeVotesModal" />

    <!-- #03 -->

    <ToastContainer />

    <ComingSoonModal v-model="authStore.showComingSoonModal" />
  </div>
</template>
