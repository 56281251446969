<script lang="ts" setup>
import ChatButton from './chat/ChatButton.vue';
import ProfileButton from './profile/ProfileButton.vue';
import LoginButton from './LoginButton.vue';
import MyVirtualWorldButton from './MyVirtualWorldButton.vue';
import useProfile from '@/composables/useProfile';
import CoinsButton from './coins/CoinsButton.vue';
// import { useWalletStore } from '@/stores/wallet'; #02

const { hasUser, isLoadingUser } = useProfile();
// const walletStore = useWalletStore(); #02
</script>

<template>
  <div class="flex w-full flex-nowrap items-center justify-end gap-5">
    <template v-if="!isLoadingUser">
      <div class="flex w-full items-center justify-end gap-5">
        <CoinsButton v-if="hasUser" />
        <MyVirtualWorldButton v-if="hasUser" class="hidden md:block" />
        <router-link
          v-if="hasUser"
          data-testid="header-search-icon"
          to="/search"
          class="flex"
        >
          <IconifyIcon
            class="text-white"
            width="25px"
            icon="iconamoon:search"
          />
        </router-link>
      </div>
      <div class="flex items-center gap-5">
        <template v-if="!hasUser">
          <LoginButton />
        </template>
        <template v-if="hasUser">
          <!-- #02 -->
          <ChatButton />
          <ProfileButton />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="flex animate-pulse items-center gap-5">
        <div
          class="h-[20px] w-[100px] rounded-full bg-neutral-800 shadow-md"
        ></div>
        <div
          class="hidden h-[40px] w-[160px] rounded-full bg-neutral-800 shadow-md md:block"
        ></div>
        <div class="size-[25px] rounded-full bg-neutral-800 shadow-md"></div>
        <div class="size-[25px] rounded-full bg-neutral-800 shadow-md"></div>
        <div class="size-[40px] rounded-full bg-neutral-800 shadow-md"></div>
      </div>
    </template>
  </div>
</template>
