<script setup lang="ts">
import { computed } from 'vue';
import GenericModal from './GenericModal.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';

interface Props {
  modelValue: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <GenericModal
    v-model="show"
    size="xs"
    hidden-title
    container-class="bg-[#0E0021]"
  >
    <div
      class="flex flex-col items-center gap-4 text-pretty px-2 pt-4 text-base/6 text-white"
    >
      <p>
        To enjoy a complete experience, we invite you to access from your PC.
      </p>
      <p>✨ Coming soon, you'll be able to download our app from:</p>
      <div class="flex justify-center gap-6">
        <div class="flex gap-2.5">
          <IconifyIcon icon="logos:google-play-icon" class="size-5" /> Google
          Play
        </div>
        <div class="flex gap-2.5">
          <IconifyIcon
            icon="simple-icons:appstore"
            class="size-5 text-[#0c57ef]"
          />
          Google Play
        </div>
      </div>
      <p class="text-2xl font-bold tracking-wider">Stay tuned! 🚀</p>
      <ButtonComponent
        variant="secondary"
        width="md"
        class="w-fit"
        @click="emit('update:modelValue', false)"
        >Close</ButtonComponent
      >
    </div>
  </GenericModal>
</template>
