<script setup lang="ts">
import { computed } from 'vue';
import GenericModal from '@/components/modals/GenericModal.vue';
import ButtonComponent from '../common/ButtonComponent.vue';

interface Props {
  modelValue: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <GenericModal
    v-model="show"
    container-class="bg-primary-gradient"
    title-class="text-2xl font-bold"
    title-class-container="justify-center -mt-2"
    title="Bienvenido a la votación"
  >
    <div class="flex flex-col gap-6 text-white sm:text-lg">
      <p class="text-pretty">
        En este sistema podrás votar por tu influencer favorito.
      </p>
      <p class="text-pretty">
        Completa las votaciones en las distintas categorías y participa por
        grandes premios.
      </p>
      <img
        class="h-32 w-full rounded-lg object-cover"
        src="https://storage.googleapis.com/legendaryum-image/welcome-votes"
        alt="Welcome Message"
      />
      <div class="flex size-full flex-col items-center">
        <router-link :to="{ name: 'VotesView' }" @click="show = false">
          <ButtonComponent
            variant="secondary"
            height="md"
            width="lg"
            font-weight="bold"
            @click="show = false"
          >
            Iniciar votación
          </ButtonComponent>
        </router-link>
      </div>
    </div>
  </GenericModal>
</template>

<style scoped></style>
